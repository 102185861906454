<template>
    <section>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-md-flex flex-md-row flex-sm-column justify-content-between align-items-center mt-5 mb-3">
                            <span>
                                <h3>Liste des dates de fermeture</h3>
                                <label class="text-muted">Cliquer sur une fermeture pour l'annuler</label>
                            </span>
                            <block-date />
                        </div>
                        <!-- tableau -->
                        <VueCal
                        class="time"
                        :time-from="9 * 60"
                        :time-to="19 * 60"
                        :time-step="30"
                        :events="events"
                        :editable-events="{ title: true, drag: false, resize: true, delete: true, create: false }"
                        locale="fr"
                        :hideWeekdays="[1,7]"
                        :disableViews="['years','year']"
                        :on-event-click="onEventClick"
                        />
                    </div>
                    <b-modal id="modal"  hide-footer title="Fermeture">
                        <div class="card">
                            <div class="card-body" >
                                <h3>Date début</h3>
                                <p>{{getDate(selected.start)}}</p>
                                <h3>Date Fin</h3>
                                <p>{{getDate(selected.end)}}</p>
                            </div>
                            <hr>
                            <div class="d-flex justify-content-end my-3">
                                <button class="btn btn-link">Annuler</button>
                                <button class="btn btn-danger mx-4" @click="deleteDate()">Supprimer</button>
                            </div>
                            <div v-if="isload" class="jumping-dots-loader">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </b-modal>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
// import 'vue-cal/dist/i18n/fr.js'
import blockDate from '../../components/functions/block-date.vue'
import {dateMixin} from '../../pages/mixins/date.js'
export default {
    name:'blockdate',
    mixins:[dateMixin],
    components:{
        VueCal,
        blockDate
    },
    data(){
        return{
            purchases:[],
            events:[],
            selected:{
                start: new Date(),
                end: new Date()
            },
            isload:false
        }
    },
    methods:{
        getOrders(){
            axios.get(`orders/01FY46XE8PN27S18ZJYS2GEFKZ`)
            .then(res=>{
                let events = []
                let purchases = res.data.purchases
                for (let service of purchases) {
                   events.push({
                       start : this.formatDate(service.datestart),
                       end : this.formatDate(service.dateend),
                       title : "fermeture",
                       class: "health",
                       ulid:service.ulid
                   })
                }
                this.events = events
            })

            .catch(errGetOrder=>console.log(errGetOrder))
        },
        onEventClick(event){
            console.log({event});
            this.selected = event
            this.$bvModal.show('modal')
        },
        deleteDate(){
            axios.delete(`purchases/${this.selected.ulid}`)
            .then(resDelete => {
                console.log(resDelete)
                this.getOrders()
                this.isload=false
                this.$bvModal.hide('modal')
                alert('supprimé avec succès')
                })
            .catch(errDelete => console.log(errDelete))
        },
        getDate(date){
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
            date = date.toLocaleDateString('fr-FR', options)
            return date
        },
    },
    mounted(){
        this.getOrders()
    }
}
</script>
<style scoped>
::v-deep .health{
    background: #1bcfb4;
    color: #fff;
}
.time{
    min-height: 50rem;
}
::v-deep .vuecal__event{
    cursor: pointer;
}
</style>